<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">ZGOLD VARIABLE</h1>
    </div>
    <div class="title-tabs mt-3">Gold VARIABLE</div>
    <div class="no-gutters bg-white p-3">
      <div class="container px-4">
        <b-row class="mb-2">
          <b-col cols="1"> </b-col>
          <b-col cols="3"> Variable <span class="text-error">*</span> </b-col>
          <b-col cols="4"> Description</b-col>
          <b-col cols="3">Value <span class="text-error">*</span></b-col>
        </b-row>
        <b-row v-for="(item, index) in form.goldList" :key="index">
          <b-col cols="1"> </b-col>
          <b-col cols="3">
            <InputText
              type="text"
              disabled
              v-model="item.name"
              placeholder="Gold Type"
              :v="$v.form.goldList.$each[index].name"
              :isValidate="$v.form.goldList.$each[index].name.$error"
            ></InputText>
          </b-col>
          <b-col cols="4"
            ><InputTextArea
              maxCharacter=""
              textFloat=""
              isHideLength
              :maxLength="999"
              type="text"
              rows="3"
              v-model="item.description"
              placeholder="Description"
            ></InputTextArea>
          </b-col>
          <b-col cols="3">
            <InputText
              type="text"
              @blur="handleBlurBath(index, 1)"
              @onKeypress="handleKeyPress"
              @input="(val, e) => formatCurrency(val, e, index)"
              v-model="item.formatValue"
              placeholder="0.00"
              :v="$v.form.goldList.$each[index].formatValue"
              :isValidate="$v.form.goldList.$each[index].formatValue.$error"
            ></InputText>
          </b-col>
        </b-row>
      </div>
    </div>
    <FooterAction routePath="/setting/gold-product" @submit="saveForm()" />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: { goldList: [] },
      filter: {
        page: 1,
        take: 999,
      },
      isLoading: false,
    };
  },

  created() {
    this.getList();
  },

  validations() {
    return {
      form: {
        goldList: {
          $each: {
            formatValue: { required },
            name: { required },
          },
        },
      },
    };
  },

  methods: {
    async getList() {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(
        `/setting/goldvariable/list`,
        this.filter
      );
      this.form.goldList = res.data.detail.data.map((x) => ({
        ...x,
        formatValue: this.formatPrice(x.value),
      }));

      this.$bus.$emit("hideLoading");
    },
    formatPrice(value) {
      return Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });
    },
    formatCurrency(val, e, index) {
      let value = val.replace(/[^0-9.]/g, "");
      const input = e.target;
      const cursorPos = input.selectionStart;
      const oldLength = input.value.length;

      if (!value) {
        this.form.goldList[index].formatValue = "";
        this.form.goldList[index].value = "";
        return;
      }
      let numericValue = parseFloat(value);
      const formattedValue = this.formatPrice(numericValue);
      this.form.goldList[index].value = numericValue;
      this.form.goldList[index].formatValue = formattedValue;

      this.$nextTick(() => {
        const newLength = input.value.length;
        const lengthDiff = newLength - oldLength;
        const newCursorPos = cursorPos + lengthDiff;
        input.setSelectionRange(newCursorPos, newCursorPos);
      });
    },

    handleKeyPress(event) {
      const char = event.key;
      const value = event.target.value;
      const cursorPos = event.target.selectionStart;

      if (!/[\d.,]/.test(char)) {
        event.preventDefault();
      }
      if (
        (char === "." && value.includes(".")) ||
        (char === "," && value.includes(","))
      ) {
        event.preventDefault();
      }

      const decimalIndex = value.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPlaces = value.length - decimalIndex - 1;
        if (decimalPlaces >= 2 && cursorPos > decimalIndex) {
          event.preventDefault();
        }
      }
    },

    handleBlurBath(index) {
      let value = this.form.goldList[index].formatValue;
      if (!value.includes(".") && value) {
        this.form.goldList[index].formatValue += ".00";
      } else {
        let parts = value.split(".");
        if (parts[1].length === 1) {
          this.form.goldList[index].formatValue += "0";
        }
      }
    },
    addGoldType() {
      this.form.goldList.push({
        id: 0,
        name: "",
        priceBaht: 0.0,
        sortOrder: 1,
        createdBy: this.$cookies.get("back_office_admin_user_guid"),
        updatedBy: this.$cookies.get("back_office_admin_user_guid"),
        deleted: 0,
      });
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(
        `/setting/goldvariable/update`,
        this.form.goldList
      );
      if (res.data.result) {
        this.successAlert().then(() => {
          this.$router.push("/setting/gold-product");
        });
      } else {
        this.errorAlert(res.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>

<style></style>
